<template>
  <div class="footer">
    <p class="mail">katerina.kapounkova@gmail.cz</p>
<!--    <p class="copyright">Copyright © 2022 Dana Suchomelova</p>-->
  </div>
</template>


<style scoped>
.footer{
  width: 100%;
  height: 90px;
  background-color: #f1f4f4;
  position: relative;
  display: flex;
  flex-direction: column;
}

.footer p{
  margin: 0;
}

.mail{
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
  padding-top: 40px;
  color: gray;
}

.copyright{
  font-size: 14px;
  padding-top: 10px;
  font-family: 'Poppins', sans-serif;
  color: dimgrey;

}
</style>